import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
// import eventsImage1 from "../../assests/images/events/commetee_meeting.jpg";
// import eventsImage2 from "../../assests/images/events/hec.png";
// import eventsImage3 from "../../assests/images/events/national_farmer.jpg";
// import eventsImage4 from "../../assests/images/events/SIFC.jpg";
// import eventsImage5 from "../../assests/images/events/events4.jpg";
// import eventsImage6 from "../../assests/images/events/events5.jpg";
// import eventsImage7 from "../../assests/images/events/events6.jpg";
// import eventsImage8 from "../../assests/images/events/events7.jpg";
// import eventsImage9 from "../../assests/images/events/events8.jpg";
// import eventsImage20 from "../../assests/images/events/events9.jpg";
const apiUrl = process.env.REACT_APP_API_URL;
const imaeglinkservrt = process.env.REACT_APP_STAGGING_URL_FOR_Images;
function Events() {
  const [data, setData] = useState([]);
  const slider = React.useRef(null);
  // const imaeglinkservrt = "https://staging.nitb.gov.pk:8620/";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}all-events`);
        setData(response.data);
        // console.log(response.data.data);
      } catch (error) {
        // setError(error);
        // console.log(error);
      } finally {
        // console.log("final");
      }
    };

    fetchData();
  }, []);

  const truncateWords = (text, numWords) => {
    const words = text.split(" ");
    return (
      words.slice(0, numWords).join(" ") +
      (words.length > numWords ? " ..." : "")
    );
  };
  var settings1 = {
    arrows: false,

    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 50,
    cssEase: "linear",
    initialSlide: 0,
    pauseOnHover: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="py-4 md:py-24">
      {" "}
      <div className="flex flex-col justify-end items-center">
        <div className="headingTestonimial py-12 !text-5xl">Events</div>
        <div className="">
          {/* <button className="AllEvents p-2"> */}
          <div class="flex flex-row items-center justify-center ">
            <Link to="/newsandevents">
              <a class="btn !capitalize">All Events </a>{" "}
            </Link>
          </div>
        </div>
      </div>
      <div className="overflow-x-hidden mt-[40px]">
        <Slider {...settings1}>
          {data &&
            data.map((event) => (
              <Link to={`/event/${event.id}`} key={event.id}>
                <>
                  <div
                    class="bg-cover w-[360px] h-[345px] bg-no-repeat mx-2"
                    style={{
                      backgroundImage: `url(${
                        imaeglinkservrt + event.main_image
                      })`,
                    }}
                  >
                    <div className="bg-[#073332] opacity-70 px-4 pb-4 pt-20 h-full w-full flex flex-col justify-end">
                      <a>
                        <h2 class="mb-2 text-2xl  tracking-tight text-white">
                          {truncateWords(event.title, 8)}
                        </h2>
                      </a>
                    </div>
                  </div>
                </>
              </Link>
            ))}
        </Slider>
      </div>
    </div>
  );
}
export default Events;
