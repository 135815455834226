import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import Footer from "../subcomponents/footer";
import "./WhyInvest.css";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import pic from "../../assests/images/Rectangle 3451.png";
// import Image from "react/image";
const apiUrl = process.env.REACT_APP_API_URL;
const imaeglinkservrt = process.env.REACT_APP_STAGGING_URL_FOR_Images;
export default function Insights() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}insights`);
        setData(response?.data || []);
        // console.log(response);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchData();
  }, []);
  var settings1 = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slider = React.useRef(null);

  return (
    <>
      <div className="bgPictureInsights">
        <div
          className="relative bg-cover bg-center h-[554px] overflow-hidden bg-white bgPictureInsights"
          style={{
            transition: "background-image 1s ease", // Smooth transition
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav className="w-full  fixed top-0 z-50 " style={navbarStyle}>
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center p-10">
                <h1 className="missionBoxHeading !text-white !text-5xl">
                  Insights{" "}
                </h1>{" "}
                <br></br>
                <p className="leaderdesig !text-xl"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-10 pb-10 flex flex-col items-center justify-center">
        <div className="contianer-width hidden md:flex flex-col items-center justify-center mb-6">
          <div className="flex flex-col justify-center items-center">
            <div className="container px-8 mt-8">
              <div>
                <div className="flex flex-row flex-wrap justify-center items-center w-full gap-4">
                  {data.map((item) => (
                    <div
                      key={item.id}
                      className="  rounded-md   bg-cover flex flex-row flex-wrap w-[300px]  h-[300px] gap-4 bg-no-repeat"
                      style={{
                        backgroundImage: `url(${imaeglinkservrt + item.image})`,
                      }}
                    >
                      <div className=" bg-gradient-to-t from-[#073332] from-10% rounded-md px-4 flex flex-col justify-end gap-4 pb-4 pt-20 h-full w-full">
                        <a
                          href={item.link || imaeglinkservrt + item.file}
                          target="_blank"
                          className="mb-2 text-lg font-semibold !normal-case tracking-tight text-white"
                        >
                          {item.heading}
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block md:hidden mb-12">
        <Slider {...settings1}>
          <div class="rounded-md h-80  bg-cover bg-no-repeat bgPakLR">
            <div className=" bg-gradient-to-t from-[#073332] from-10% rounded-md px-4 flex flex-col justify-end gap-4 pb-4 pt-20 h-full w-full">
              <a
                target="_blank"
                href="./public/24253-Pakistan-report-updates-LR.pdf"
              >
                <a class="mb-2 text-lg font-semibold tracking-tight text-white">
                  Pakistan: progressing towards a fully fledged digital economy{" "}
                </a>
              </a>
            </div>
          </div>{" "}
          <div class="rounded-md h-80  bg-cover bg-no-repeat bgAgriINNo">
            <div className=" bg-gradient-to-t from-[#073332] from-10% rounded-md px-4 flex flex-col justify-end gap-4 pb-4 pt-20 h-full w-full">
              <a
                target="_blank"
                href="./public/Report-Agriculture-and-Livestock-Innovation-System-in-Punjab.pdf"
              >
                <a class="mb-2 text-lg font-semibold tracking-tight text-white">
                  Agriculture Gricultural & Livestock Innovation System:
                  Achievements, Constraints, and Ways Forward
                </a>
              </a>
            </div>
          </div>
          <div class="rounded-md h-80  bg-cover bg-no-repeat bgIndusP">
            <div className=" bg-gradient-to-t from-[#073332] from-10% rounded-md px-4 flex flex-col justify-end gap-4 pb-4 pt-20 h-full w-full">
              <a
                target="_blank"
                href="https://documents.worldbank.org/en/publication/documents-reports/documentdetail/099610110262210535/idu0b0587cf00758f047e70922c0bacdbf9bc8ca"
              >
                <a class="mb-2 text-lg font-semibold tracking-tight text-white">
                  Enhancing Smallholder Incomes by Linking to High Value Markets
                  in Pakistan’s Punjab and Sindh Provinces (English)
                </a>
              </a>
            </div>
          </div>{" "}
          <div class="rounded-md h-80  bg-cover bg-no-repeat bgPakDev">
            <div className=" bg-gradient-to-t from-[#073332] from-10% rounded-md px-4 flex flex-col justify-end gap-4 pb-4 pt-20 h-full w-full">
              <a href="./public/PAKISTAN DEVELOPMENT UPDATE.pdf">
                <a class="mb-2 text-lg font-semibold tracking-tight text-white">
                  PAKISTAN DEVELOPMENT UPDATE Restoring Fiscal Sustainability
                </a>
              </a>
            </div>
          </div>{" "}
          <div class="rounded-md h-80  bg-cover bg-no-repeat bgIgnite">
            <div className=" bg-gradient-to-t from-[#073332] from-10% rounded-md px-4 flex flex-col justify-end gap-4 pb-4 pt-20 h-full w-full">
              <a href="./public/Ignite-Startup-Report.pdf">
                <a class="mb-2 text-lg font-semibold tracking-tight text-white">
                  Study for Assessment of Pakistan’s Startup Ecosystem{" "}
                </a>
              </a>
            </div>
          </div>{" "}
        </Slider>
      </div>
      <Footer />
    </>
  );
}
