import React, { useEffect, useState } from "react";
import NavBar from "../NavBar";
import Footer from "../subcomponents/footer";
import "./WhyInvest.css";
import ImageDownloadButton from "../subcomponents/ImageDownloadButton";

export default function Tenders() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navbarStyle = {
    backgroundColor: scrolling ? "white" : "transparent", // Change color based on scrolling
    transition: "background-color 0.3s ease", // Add a smooth transition
    color: scrolling ? "#000" : "#fff",
  };

  const [activeTab, setActiveTab] = useState("active"); // 'active' or 'archived'

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="bgPictureTenders">
        <div
          className="relative bg-cover bg-center h-[554px] overflow-hidden bg-white bgPictureTenders"
          style={{
            transition: "background-image 1s ease", // Smooth transition
          }}
        >
          {/* Navbar */}
          <div className="relative">
            <nav className="w-full  fixed top-0 z-50 " style={navbarStyle}>
              <NavBar />
            </nav>
          </div>
          <div className="absolute inset-y-0 left-0 text-black flex flex-col justify-center items-center w-full">
            <div className="container mx-auto mt-[60px]">
              <div className="contianer-width flex flex-col items-center p-10">
                <h1 className="missionBoxHeading !text-white !text-3xl md:!text-5xl">
                  Tenders
                </h1>{" "}
                <br></br>
                <p className="leaderdesig !text-xl"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-6 md:py-[150px]">
        <div className="flex flex-row justify-center items-center mx-4 gap-[60px]">
          <button
            className={`w-[230px]  text-${
              activeTab === "active" ? "[#056839]" : "[#073332]"
            } 
            
            Poppins text-[18px] font-${
              activeTab === "active" ? "semibold" : "normal"
            } bg-[#F8F8F8] py-[16px] border border-${
              activeTab === "active" ? "[#F08A23]" : "[#EEE]"
            } rounded-xl`}
            onClick={() => handleTabChange("active")}
          >
            Active Tenders
          </button>
          <button
            className={`w-[230px] text-${
              activeTab === "archived" ? "[#056839]" : "[#073332]"
            } Poppins text-[18px] font-${
              activeTab === "archived" ? "semibold" : "normal"
            } py-[16px] border border-${
              activeTab === "archived" ? "[#F08A23]" : "[#EEE]"
            } rounded-xl`}
            onClick={() => handleTabChange("archived")}
          >
            Archived Tenders
          </button>
        </div>

        <div className="flex flex-row items-center justify-center mx-2">
          <div className="contianer-width">
            {activeTab === "active" && (
              <table className="w-full border-collapse mt-4 md:mt-8">
                <thead>
                  <tr>
                    <th className="py-2 px-4 bg-green-700 text-left text-white border-b border-[#E4E7E7]">
                      Sr. No
                    </th>
                    <th className="py-2 px-4 bg-green-700 text-left text-white border-b border-[#E4E7E7]">
                      Title
                    </th>
                    <th className="py-2 px-4 bg-green-700 text-left text-white border-b border-[#E4E7E7]">
                      Date
                    </th>
                    <th className="py-2 px-4 bg-green-700 text-left text-white border-b border-[#E4E7E7]">
                      Download
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-left">1</td>
                    <td className="text-left">
                      Tender notice for pre-qualification of contractors/
                      suppliers/ firms/ workshops
                    </td>
                    <td className="text-left">Sep 10, 2024 at 1100 hrs</td>
                    <td className="text-left hover:underline text-blue-500 cursor-pointer">
                      <ImageDownloadButton imageUrl="/images/tender3.jpg" />
                    </td>
                    {/* <td className="text-left">Download/View</td> */}
                  </tr>
                  <tr>
                    <td className="text-left">2</td>
                    <td className="text-left">
                      Tender notice for supply of office/computer stationery and
                      miscellanous items
                    </td>
                    <td className="text-left">Aug 28, 2024 at 1100 hrs</td>
                    <td className="text-left hover:underline text-blue-500 cursor-pointer">
                      <ImageDownloadButton imageUrl="/images/tender2.jpg" />
                    </td>
                    {/* <td className="text-left">Download/View</td> */}
                  </tr>
                  {/* Your table rows go here */}
                  <tr>
                    <td className="text-left">3</td>
                    <td className="text-left">
                      Pre-qualification for Repair, Maintenance Services &
                      Purchase of Essential Items{" "}
                    </td>
                    <td className="text-left">Aug 26, 2024 at 1100 hrs</td>
                    <td className="text-left hover:underline text-blue-500 cursor-pointer">
                      <ImageDownloadButton imageUrl="/images/tender1.jpeg" />
                    </td>
                    {/* <td className="text-left">Download/View</td> */}
                  </tr>
                  {/* Add more rows as needed */}
                </tbody>
              </table>
            )}
            {activeTab === "archived" && (
              <table className="w-full border-collapse mt-4 md:mt-8">
                <thead>
                  <tr>
                    <th className="py-2 px-4 bg-green-700 text-left text-white border-b border-[#E4E7E7]">
                      Sr. No
                    </th>
                    <th className="py-2 px-4 bg-green-700 text-left text-white border-b border-[#E4E7E7]">
                      Title
                    </th>
                    <th className="py-2 px-4 bg-green-700 text-left text-white border-b border-[#E4E7E7]">
                      Start Date
                    </th>
                    <th className="py-2 px-4 bg-green-700 text-left text-white border-b border-[#E4E7E7]">
                      End Date
                    </th>
                    <th className="py-2 px-4 bg-green-700 text-left text-white border-b border-[#E4E7E7]">
                      Download
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* Your table rows go here */}
                  <tr>
                    <td className="text-left">1</td>
                    <td className="text-left">
                      Evaluation report regarding pre-qualification of firms for
                      services for SIFC Secretariat at PPRA Website
                    </td>
                    <td className="text-left">Feb 16, 2024 at 1100 hrs</td>
                    <td className="text-left">Feb 16, 2024 at 1130 hrs</td>
                    <td className="text-left hover:underline text-blue-500 cursor-pointer">
                      <ImageDownloadButton imageUrl="/images/tender.jpeg" />
                    </td>
                  </tr>
                  {/* Add more rows as needed */}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
