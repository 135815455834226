import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { Link } from "react-router-dom";
export default function Vistas() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const imaeglinkservrt = process.env.REACT_APP_STAGGING_URL_FOR_Images;
  const [data, setData] = useState([]);
  // const imaeglinkservrt = "https://staging.nitb.gov.pk:8620/";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}home-sectors`);
        setData(response.data);
        //console.log("responses", response.data);
      } catch (error) {
        // setError(error);
        // console.log(error);
      } finally {
        // console.log("final");
      }
    };

    fetchData();
  }, []);

  const truncateWords = (text, numWords) => {
    const words = text.split(" ");
    return (
      words.slice(0, numWords).join(" ") +
      (words.length > numWords ? " ..." : "")
    );
  };
  var settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    // slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    arrows: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          // slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          // slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 1,
        },
      },
    ],
  };
  const slider = React.useRef(null);

  return (
    <div className="pb-4 md:pb-24">
      {" "}
      <div className="flex flex-col justify-end items-center">
        <div className="headingTestonimial py-12 md:!text-[40px] !text-[30px] !normal-case flex gap-5 items-center">
          Vistas of Investment{" "}
          <div className="flex flex-row gap-[30px] ">
            <span
              className="cursor-pointer"
              onClick={() => slider?.current?.slickPrev()}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.2594 17.8666L7.39326 10.0005L15.2594 2.13436C15.4914 1.89946 15.6211 1.58227 15.6201 1.25206C15.6191 0.921861 15.4875 0.605467 15.254 0.371976C15.0205 0.138485 14.7041 0.00686749 14.3739 0.00586514C14.0437 0.00486279 13.7265 0.134557 13.4916 0.366626L4.74162 9.11663C4.50721 9.35105 4.37551 9.66898 4.37551 10.0005C4.37551 10.332 4.50721 10.6499 4.74162 10.8844L13.4916 19.6344C13.7265 19.8664 14.0437 19.9961 14.3739 19.9951C14.7041 19.9941 15.0205 19.8625 15.254 19.629C15.4875 19.3955 15.6191 19.0791 15.6201 18.7489C15.6211 18.4187 15.4914 18.1015 15.2594 17.8666Z"
                  fill="#22574d"
                />
              </svg>
            </span>{" "}
            <span
              className="cursor-pointer"
              onClick={() => slider?.current?.slickNext()}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.74065 17.8666L12.6067 10.0005L4.74065 2.13436C4.50858 1.89946 4.37889 1.58227 4.37989 1.25206C4.38089 0.921861 4.51251 0.605467 4.746 0.371976C4.97949 0.138485 5.29588 0.00686749 5.62609 0.00586514C5.95629 0.00486279 6.27348 0.134557 6.50838 0.366626L15.2584 9.11663C15.4928 9.35105 15.6245 9.66898 15.6245 10.0005C15.6245 10.332 15.4928 10.6499 15.2584 10.8844L6.50838 19.6344C6.27348 19.8664 5.95629 19.9961 5.62609 19.9951C5.29588 19.9941 4.97949 19.8625 4.746 19.629C4.51251 19.3955 4.38089 19.0791 4.37989 18.7489C4.37889 18.4187 4.50858 18.1015 4.74065 17.8666Z"
                  fill="#22574d"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div className="overflow-x-hidden  mx-4">
        <Slider ref={slider} {...settings1}>
          {data &&
            data.map((event) => (
              // <Link to={`/event/${event.id}`} key={event.id}>
              <>
                <div class="w-[320px] h-[450px] mx-2  border-[#329244] border-2 p-2">
                  <img src={imaeglinkservrt + event.image} alt="" />

                  <h2 class="my-1 text-xl text-center tracking-tight text-black font-semibold">
                    {truncateWords(event.heading, 3)}
                  </h2>
                  <p className="text-gray-500  text-center">
                    {truncateWords(event.description, 3)}
                  </p>
                  <div className="flex justify-center gap-2">
                    {" "}
                    <a
                      href={imaeglinkservrt + event.link}
                      className="text-[#329244]  text-center font-bold text-sm mb-4"
                    >
                      Read more{" "}
                    </a>{" "}
                    <svg
                      class="w-6 h-6 !text-[#329244]"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 12H5m14 0-4 4m4-4-4-4"
                      />
                    </svg>
                  </div>
                </div>
              </>
              // </Link>
            ))}
        </Slider>
      </div>
    </div>
  );
}
