import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./EventSlider.css";
import axios from "axios";

import { Link } from "react-router-dom";
const apiUrl = process.env.REACT_APP_API_URL;
const imaeglinkservrt = process.env.REACT_APP_STAGGING_URL_FOR_Images;
function EventSlider() {
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // const imaeglinkservrt = "https://staging.nitb.gov.pk:8620/";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}all-stories`);
        setStories(response.data);
        // console.log(response.data, "yyyyyyyyyyy");
      } catch (error) {
        // setError(error);
        // console.log(error);
      } finally {
        // setLoading(false);
        // console.log("asd");
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const originalDate = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return originalDate.toLocaleDateString("en-US", options);
  };

  const slides = stories?.map((item) => (
    <div key={item.id}>
      <>
        <div
          class="bg-contain md:bg-cover bg-center bg-no-repeat w-[280px] md:w-[425px] lg:w-[470px] h-32 md:h-[280px] mx-1"
          style={{
            backgroundImage: `url(${imaeglinkservrt + item?.image})`,
          }}
        >
          <div className="bg-gradient-to-t from-[#22574D] to-[#83CEC126] from-5% px-4 pb-4 pt-20 h-full w-full flex flex-col justify-end">
            <Link to={`/success_stories/details/${item?.id}`}>
              <h2 className="!text-[10px] md:text-base text-center lg:text-left font-normal Poppins leading-[30px]  z-10 text-white">
                {item?.title}
              </h2>
            </Link>
            <h5 className="textSuccess !text-[10px] md:text-base text-center lg:text-left ">
              {formatDate(item?.date)}{" "}
            </h5>
          </div>
        </div>
      </>
    </div>
  ));

  // let slides = [
  //   <>
  //     <div className="relative z-20 w-full flex justify-center">
  //       <img src={ss2} alt="1" className="h-full w-full object-cover" />
  //       <div className="havingtheOpacity"></div>

  //       <div className=" text-white mt-0 z-10 w-full lg:px-10 bg-gradient-to-t  from-[#22574D] to-[#83CEC126] py-3 absolute bottom-0 ">
  //         <h2 className=" text-base font-normal Poppins leading-[30px] relative z-10 text-white  ">
  //           Reinvigorating TAPI Project
  //         </h2>

  //         <h5 className="textSuccess ">25 Oct 2023</h5>
  //       </div>
  //     </div>
  //   </>,

  //   <>
  //     <div className="relative z-20 w-full flex justify-center">
  //       <img src={ss3} alt="1" className="h-full  w-full object-cover  " />
  //       <div className="havingtheOpacity"></div>

  //       <div className=" text-white mt-0 z-10 w-full lg:px-10 bg-gradient-to-t  from-[#22574D] to-[#83CEC126] py-3 absolute bottom-0 ">
  //         <h2 className="text-white text-base font-normal Poppins leading-[30px] relative z-10  ">
  //           MOU signed between GB Govt. and NorHydro for Hydro and Solar Power
  //           Plants
  //         </h2>

  //         <h5 className="textSuccess ">23 Oct 2023</h5>
  //       </div>
  //     </div>
  //   </>,
  //   <>
  //     <div className="relative z-20 w-full flex justify-center">
  //       <img src={ss4} alt="1" className="h-full  w-full object-cover  " />
  //       <div className="havingtheOpacity"></div>

  //       <div className=" text-white mt-0 z-10 w-full lg:px-10 bg-gradient-to-t  from-[#22574D] to-[#83CEC126] py-3 absolute bottom-0 ">
  //         <h2 className="text-white text-base font-normal Poppins leading-[30px] relative z-10  ">
  //           MOU signed between Petroleum Division and Chinese Company (UEG)
  //         </h2>

  //         <h5 className="textSuccess ">18 Oct 2023</h5>
  //       </div>
  //     </div>
  //   </>,

  //   <>
  //     <div className="relative z-20 w-full flex justify-center">
  //       <img src={ss5} alt="1" className="h-full  w-full object-cover  " />
  //       <div className="havingtheOpacity"></div>

  //       <div className=" text-white mt-0 z-10 w-full lg:px-10 bg-gradient-to-t  from-[#22574D] to-[#83CEC126] py-3 absolute bottom-0 ">
  //         <h2 className="text-white text-base font-normal Poppins leading-[30px] relative z-10 ">
  //           1st PPP Solar Project by NPAK in GB
  //         </h2>

  //         <h5 className="textSuccess ">16 Oct 2023</h5>
  //       </div>
  //     </div>
  //   </>,
  //   // <>
  //   //   <div className="relative z-20 w-full flex justify-center">
  //   //     <img
  //   //       src={successsImage6}
  //   //       alt="1"
  //   //       className="h-full  w-full object-cover  "
  //   //     />
  //   //     <div className="havingtheOpacity"></div>

  //   //     <div className=" text-white mt-0 z-10 w-full lg:px-10 bg-gradient-to-t  from-[#22574D] to-[#83CEC126] py-3 absolute bottom-0 ">
  //   //       <h2 className="succesHeading text-white  ">
  //   //         Improving Ecosystem of IT & Telecom
  //   //       </h2>

  //   //       <h5 className="textSuccess ">16 Oct 2023</h5>
  //   //     </div>
  //   //   </div>
  //   // </>,
  //   <>
  //     <div className="relative z-20 w-full flex justify-center">
  //       <img src={ss9} alt="1" className="h-full  w-full object-cover  " />
  //       <div className="havingtheOpacity"></div>

  //       <div className=" text-white mt-0 z-10 w-full lg:px-10 bg-gradient-to-t  from-[#22574D] to-[#83CEC126] py-3 absolute bottom-0  ">
  //         <h2 className="text-white text-base font-normal Poppins leading-[30px] relative z-10  ">
  //           Launch of Revised Visa Policy Through SIFC platform
  //         </h2>
  //         <h5 className="textSuccess ">09 Sep 2023</h5>
  //       </div>
  //     </div>
  //   </>,
  //   // <>
  //   //   <div className="relative z-20 w-full flex justify-center">
  //   //     <img src={successsImage8} alt="1" className="h-full  w-full object-cover  " />
  //   //     <div className=" text-white mt-0 z-10 w-full lg:px-10 bg-gradient-to-t  from-[#22574D] to-[#83CEC126] py-3 absolute bottom-0 ">
  //   //       <h2 className="succesHeading text-white  ">7th apex Committee Meeting</h2>

  //   //       <h5 className="textSuccess ">16 Oct 2023</h5>
  //   //     </div>
  //   //   </div>
  //   // </>,
  //   // <>
  //   //   <div className="relative z-20 w-full flex justify-center ">
  //   //     <img src={successsImage9} alt="1" className="h-full  w-full object-cover  " />
  //   //     <div className="text-white mt-0 z-10 w-full lg:px-10 bg-gradient-to-t  from-[#22574D] to-[#83CEC126] py-3 absolute bottom-0  ">
  //   //       <h2 className="succesHeading text-white  ">
  //   //         Launch of Revised Visa Policy Through SIFC platform
  //   //       </h2>
  //   //       <h5 className="textSuccess ">09 Sep 2023</h5>
  //   //     </div>
  //   //   </div>
  //   // </>,
  //   // <>
  //   //   <div className="relative z-20 w-full flex justify-center">
  //   //     <img src={successsImage10} alt="1" className=h-full ] w-full object-cover  " />
  //   //     <div className="absolute bottom-8 z-10 w-full lg:px-10">
  //   //       <h2 className="succesHeading text-black">7th apex Committee Meeting</h2>

  //   //       <h5 className="textSuccess ">16 Oct 2023</h5>
  //   //     </div>
  //   //   </div>
  //   // </>,
  //   // <>
  //   //   <div className="relative z-20 w-full flex justify-center">
  //   //     <img src={successsImage11} alt="1" className=h-full ] w-full object-cover  " />
  //   //     <div className="absolute bottom-8 z-10 w-full lg:px-10">
  //   //       <h2 className="succesHeading text-black">7th apex Committee Meeting</h2>

  //   //       <h5 className="textSuccess ">16 Oct 2023</h5>
  //   //     </div>
  //   //   </div>
  //   // </>,
  // ];

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 3,
    speed: 500,
    arrows: false,
    autoplay: true,
    focusOnSelect: true,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1540,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  const slider = React.useRef(null);
  // slider?.current?.slickGoTo(0);

  return (
    <>
      <div
        id="success_stories"
        className="flex justify-center flex-col items-center"
      >
        <div className=" flex flex-col pt-4 md:pt-[100px] justify-center items-center">
          <div className="headingTestonimial  md:!text-[48px] !text-[30px] mb-4 md:mb-[40px]">
            Our Success stories
          </div>
          <div class="flex flex-row items-center mr-[22px]  justify-center ">
            <Link to="/success_stories">
              <a class="btn1">All Success Stories </a>{" "}
            </Link>
          </div>
        </div>
        <div className="contianer-width md:h-[600px]">
          <Slider ref={slider} {...settings}>
            {slides.map((s) => (
              <div>
                <h3 className="m-[10px]">{s}</h3>
              </div>
            ))}
          </Slider>
          {/* <span
          className="cursor-pointer"
          onClick={() => slider?.current?.slickPrev()}
        >
          {"<"}
        </span>{" "}
        <span
          className="cursor-pointer"
          onClick={() => slider?.current?.slickNext()}
        >
          {">"}
        </span> */}
        </div>
      </div>
    </>
  );
}

export default EventSlider;
